<template>
  <BaseBonusPanel
    type="freebet"
    :progress="progress"
    :data-t="`freebet-panel-${status}`"
  >
    <template #title>
      <span v-if="status === 'rolling'">
        {{ t('bonuses.freebetWin') }}
      </span>
      <span v-else class="title">
        {{ t('bonuses.freeBetPanelTitle', { amount: freebet.amount }) }}
        <StIcon :name="freebet.currencyIcon" :size="14" />
      </span>
    </template>
    <template #wager>
      <span v-if="status === 'rolling'" class="wager" data-t="wager">
        {{ t('bonuses.wager', { multiplier: wager }) }}
      </span>
    </template>
    <template #subtitle>
      <p v-if="subtitle" class="subtitle" data-t="subtitle">
        {{ subtitle }}
      </p>
    </template>
    <template #minmax>
      <div
        v-if="status === 'rolling'"
        class="rolling-min-max"
        data-t="rolling-min-max"
      >
        <span class="rolling-text">
          {{ minMaxRollingText }}
          <StIcon :name="freebet.currencyIcon" :size="14" />
        </span>

        <span class="bonus-amount" data-t="bonus-amount">
          <img :src="giftImage" alt="gift" width="20" />
          <span>
            {{ currentRewardAmount }}
          </span>
          <StIcon :name="freebet.currencyIcon" :size="14" />
        </span>
      </div>
    </template>
    <template #actions>
      <StButton
        v-if="buttonSettings"
        :label="buttonSettings.title"
        size="m"
        type="ghost"
        data-t="action-button"
        :to="buttonSettings.to"
      />
    </template>
  </BaseBonusPanel>
</template>

<script setup lang="ts">
import BaseBonusPanel from './BaseBonusPanel.vue'
import type { Freebet } from '../../../types'
import giftImage from '../assets/gift.png'
import { useFreebet } from '../../../composables/useFreebet'

interface Props {
  freebet: Freebet
}

const props = defineProps<Props>()
const { t } = useI18n()

const { freebet } = toRefs(props)

const { currentRewardAmount, minMaxRollingText, wager, status, progress } =
  useFreebet(freebet)

const subtitle = computed(() => {
  switch (status.value) {
    case 'new':
      return t('bonuses.freebetMakeBet')
    case 'process':
      return t('bonuses.freebetBetProcessed')
    default:
      return ''
  }
})

const buttonSettings = computed(() => {
  switch (status.value) {
    case 'new':
      return {
        title: t('bonuses.openSport'),
        to: '/sport',
      }
    case 'process':
      return {
        title: t('bonuses.openBets'),
        to: '/personal/bets',
      }
    default:
      return null
  }
})
</script>

<style scoped>
.wager {
  font: var(--mobile-caption-1-regular);
}

.title {
  display: flex;
  gap: var(--spacing-050);
  align-items: center;
}

.subtitle {
  margin: 0;
  font: var(--mobile-caption-1-medium);
  color: var(--text-secondary);

  span {
    color: var(--text-primary);
  }
}

.rolling-min-max {
  display: flex;
  gap: var(--spacing-025);
  align-items: center;
  font: var(--mobile-caption-1-regular);
}

.bonus-amount {
  display: flex;
  gap: var(--spacing-050);
  align-items: center;

  margin-left: auto;

  font: var(--mobile-caption-1-medium);
  color: var(--text-success);
}

.rolling-text {
  display: flex;
  gap: var(--spacing-050);
  align-items: center;
}
</style>
