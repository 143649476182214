<template>
  <BaseBonusPanel type="deposit" :progress="rollingProgress">
    <template #title>
      {{ t('bonuses.depositTitle') }}
    </template>
    <template #wager>
      <span v-if="isRolling" class="wager">
        {{ t('bonuses.wager', { multiplier: rollingMultiplier }) }}
      </span>
    </template>
    <template #minmax>
      <div v-if="isRolling" class="rolling-min-max">
        <span>
          {{ minMaxRollingText }}
        </span>
        <StIcon v-if="currencyIcon" :name="currencyIcon" :size="14" />
        <span class="bonus-amount">
          <img :src="giftImage" alt="gift" width="20" />
          <span>
            {{ bonusAmountForClaim }}
          </span>
          <StIcon v-if="currencyIcon" :name="currencyIcon" :size="14" />
        </span>
      </div>
    </template>
    <template #subtitle>
      <p v-if="isNewBonus" class="bonus-text new">
        {{ t('bonuses.depositPanelGet') }}
        <span>
          {{
            t('bonuses.depositPanelMultiplier', { multiplier: bonusMultiplier })
          }}
        </span>
      </p>
      <p v-if="isCanClaimed" class="bonus-text claim">
        {{ t('bonuses.depositPanelClaimText') }}
        <span>
          {{ bonusAmountForClaim }}
        </span>
        <StIcon v-if="currencyIcon" :name="currencyIcon" :size="14" />
      </p>
    </template>
    <template #actions>
      <StButton
        v-if="isNewBonus"
        :label="t('bonuses.depositPanelMakeDeposit')"
        size="m"
        replace
        :to="{ query: { modal: 'payments', tab: 'deposit' } }"
      />
      <div v-if="isCanClaimed" class="claim-box">
        <StButton
          :label="t('bonuses.depositPanelClaim')"
          size="m"
          :loading="claimStatus === 'pending'"
          :disabled="claimStatus === 'error'"
          @click="handleBonusClaim"
        >
          {{ t('bonuses.depositPanelClaim') }}
          <ClientOnly>
            <StLottie
              v-if="claimStatus === 'success'"
              class="animation"
              :animation-data="claimAnimation"
            />
          </ClientOnly>
        </StButton>
      </div>
    </template>
  </BaseBonusPanel>
</template>

<script setup lang="ts">
import BaseBonusPanel from './BaseBonusPanel.vue'
import { useDepositBonus } from '../../../composables/useDepositBonus'
import type { DepositBonus } from '../../../types'
import giftImage from '../assets/gift.png'
import claimAnimation from '../../../assets/claim-animation.json'

const props = defineProps<{
  bonus: DepositBonus
}>()

const { t } = useI18n()

const { bonus } = toRefs(props)

const {
  currencyIcon,
  bonusMultiplier,
  isNewBonus,
  isRolling,
  isCanClaimed,
  bonusAmountForClaim,
  currentRollingAmount,
  fullRollingAmount,
  rollingProgress,
  rollingMultiplier,
  claimStatus,
  handleBonusClaim,
} = useDepositBonus(bonus)

const minMaxRollingText = computed(
  () => `${currentRollingAmount.value} / ${fullRollingAmount.value}`,
)
</script>

<style scoped>
.bonus-text {
  display: flex;
  gap: var(--spacing-025);
  align-items: center;

  margin: 0;

  font: var(--mobile-caption-1-medium);
  color: var(--text-secondary);

  &.new {
    span {
      margin-left: var(--spacing-025);
      background: linear-gradient(
        90deg,
        #ffe86d 42.95%,
        #f8db90 65.11%,
        #feb581 91.78%,
        #f09f7c 100%
      );
      background-clip: text;

      -webkit-text-fill-color: transparent;
    }
  }

  &.claim {
    span {
      margin-left: var(--spacing-025);
      color: var(--text-success);
    }
  }
}

.rolling-min-max {
  display: flex;
  gap: var(--spacing-025);
  align-items: center;
  font: var(--mobile-caption-1-regular);
}

.wager {
  font: var(--mobile-caption-1-regular);
}

.bonus-amount {
  display: flex;
  gap: var(--spacing-050);
  align-items: center;

  margin-left: auto;

  font: var(--mobile-caption-1-medium);
  color: var(--text-success);
}

.animation {
  position: absolute;
  overflow: visible;
}
</style>
