<template>
  <section class="bonuses-widget" :class="{ mobile }" data-t="bonuses-widget">
    <header class="bonuses-top" :class="{ 'arrived-top': arrivedState.top }">
      <h2 class="title">{{ t('bonuses.availableBonuses') }}</h2>
      <StCounter
        :size="'xs'"
        bg-color="orange"
        class="counter"
        data-t="bonuses-counter"
        :label="bonusesCount"
      />
      <button v-if="mobile" class="close-button" @click="close">
        <StIcon name="cross-large" :size="20" />
      </button>
    </header>
    <div ref="el" class="scroll-container">
      <TransitionGroup name="list" tag="div" class="bonuses-list">
        <DepositBonusPanel
          v-for="bonus in depositBonuses"
          :key="bonus.id"
          :bonus="bonus"
        />
        <FreespinBonusPanel
          v-for="freespin in freespins"
          :key="freespin.userFreespinProgramId"
          :freespin="freespin"
        />
        <FreebetBonusPanel
          v-for="freebet in freebets"
          :key="freebet.id"
          :freebet="freebet"
        />
        <RegularBonusPanel
          v-for="(bonus, bonusIndex) in completedBonuses"
          :key="bonusIndex"
          :bonus="bonus"
          @claim="refreshBonuses"
        />
      </TransitionGroup>
    </div>

    <footer class="bonuses-bottom">
      <StButton
        type="gray"
        size="l"
        :label="t('bonuses.showAllBonuses')"
        @click="handleShowAll"
      />
    </footer>
  </section>
</template>

<script setup lang="ts">
import { useBonusesCountStore } from '@st/bonuses/stores/useBonusesCountStore'
import DepositBonusPanel from './parts/DepositBonusPanel.vue'
import FreespinBonusPanel from './parts/FreespinBonusPanel.vue'
import FreebetBonusPanel from './parts/FreebetBonusPanel.vue'
import RegularBonusPanel from './parts/RegularBonusPanel.vue'
import { useDepositBonusesStore } from '../../stores/useDepositBonusesStore'
import { useRegularBonusesStore } from '../../stores/useRegularBonusesStore'
import { useFreeBonuses } from '../../composables/useFreeBonuses'

defineProps<{
  mobile?: boolean
}>()

const emit = defineEmits<{
  (e: 'showAll'): void
}>()

const { t } = useI18n()

const { bonuses: depositBonuses } = storeToRefs(useDepositBonusesStore())
const { bonusesCount } = storeToRefs(useBonusesCountStore())

const { freebets, freespins } = useFreeBonuses()

const regularBonusesStore = useRegularBonusesStore()
const { refreshBonuses } = regularBonusesStore
const { completedBonuses } = storeToRefs(regularBonusesStore)

const router = useRouter()
function close() {
  router.replace({ query: {} })
}

function handleShowAll() {
  router.push('/bonuses')
  emit('showAll')
}

const el = ref<HTMLDivElement>()
const { arrivedState } = useScroll(el)

onMounted(() => {
  refreshBonuses()
})
</script>

<style scoped>
.bonuses-list {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-050);
}

.bonuses-top {
  position: relative;

  display: flex;
  gap: var(--spacing-075);
  align-items: center;

  padding: var(--spacing-125, 10px) var(--spacing-200, 16px);

  background: var(--background-primary);
  border-bottom: 1px solid var(--border-tertiary);

  &::before {
    pointer-events: none;
    content: '';

    position: absolute;
    z-index: 1;
    right: -4px;
    bottom: -32.5px;
    left: -4px;

    height: 32px;

    opacity: 0;
    background: linear-gradient(#111118 0%, rgb(17 17 24 / 0%) 100%);

    transition: opacity 0.2s linear;
  }

  &:not(.arrived-top) {
    &::before {
      opacity: 1;
    }
  }
}

.title {
  margin: 0;
  font: var(--desktop-text-md-semibold);
}

.bonuses-bottom {
  display: flex;
  flex-direction: column;
  padding: var(--spacing-150);
}

.scroll-container {
  overflow: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  min-height: 0;
  max-height: 383px;
  margin-top: var(--spacing-100);
  margin-right: calc(var(--spacing-200) * -1);
  padding-right: var(--spacing-200);
}

.bonuses-widget {
  overflow: hidden;
  display: flex;
  flex-direction: column;

  width: 360px;
  padding: var(--spacing-050);

  background: var(--background-primary);
  border-radius: var(--border-radius-150);

  &.mobile {
    width: 100%;
    padding: var(--spacing-050) var(--spacing-200) var(--spacing-0)
      var(--spacing-200);
    background: var(--background-base);
    border-radius: var(--border-radius-0);

    .bonuses-top {
      display: flex;
      gap: var(--spacing-100);

      padding: var(--spacing-100) var(--spacing-150);

      background: var(--background-base);
      border-bottom: none;
    }

    .bonuses-bottom {
      padding: var(--spacing-200) 0;
    }

    .bonuses-list {
      gap: var(--spacing-075);
    }
  }
}

.close-button {
  all: unset;

  position: absolute;
  top: 0;
  right: 4px;

  padding: var(--spacing-125);

  line-height: 0;
}

.list-move,
.list-enter-active,
.list-leave-active {
  transition:
    opacity 0.3s,
    transform 0.3s;
}

.list-enter-from,
.list-leave-to {
  transform: translateY(-16px);
  opacity: 0;
}
</style>
